<template>
  <div class="page-modal" v-if="show">
    <!-- 这是蒙版和关闭按钮 -->
    <div class="mask" @click.stop="show=false"></div>

    
    
    <!-- 这里写主体内容 -->
    <div
      class="big_right-center-z"
    >
      <div class="video-height" id="player_2" ></div>
    </div>
    <img src="@/assets/close-2.png" class="mask-close" alt="" @click="show=false">
  </div>
</template>
<script>
import axios from "axios";


const app_key = "25231058";
const app_secret = "PEP4K8hiZQAGGjmJqzvA";

const content_type="application/json";//类型
const accept="*/*" ;//accept
export default({
  data(){
    return{
      show:false,
      videoSrcZ: '',
      playerObj:null
    }
  },
  created(){

  },
  mounted(){
    // this.init()
  },
  watch: {

  },
  methods:{
    init(code){
      this.show = true;
      // this.videoSrcZ = src;
      let that = this;
      // const url = "/api/getCameraPreviewURL?cameraIndexCode=a950532bb2f344069eaf265c042e5291"
			// const time = + new Date();
			// const sign_str = `POST\n${accept}\n${content_type}\nx-ca-key:${app_key}\nx-ca-timestamp:${time}\n${url}`;
			// const hash = this.$CryptoJS.HmacSHA256(sign_str, app_secret);
			// const base64 = hash.toString(this.$CryptoJS.enc.Base64);
      // axios({
      // url: process.env.NODE_ENV === 'production' ? `http://127.0.0.1:1000${url}` : `/api${url}`,
      // method: "post",
      // withCredentials: false,
      // headers: {
			//   "Accept": accept,
			//   "Content-Type": content_type,
      //   "X-Ca-Key": app_key,
			//   "X-Ca-Signature": base64,
			//   "X-Ca-Timestamp": time,
      //   "X-Ca-Signature-Headers": "x-ca-key,x-ca-timestamp",
			//   "Access-Control-Allow-Origin": "*",
			//   "Access-Control-Allow-Headers": "content-type",
			//   "Access-Control-Request-Method": "GET,POST"
      // },
      // }).then((res) => {
      //     console.log(res)
      this.$nextTick(()=>{
          that.playerObj = new window.JSPlugin({
            szId: 'player_2',
            szBasePath: "/",
            iMaxSplit: 1,
            iCurrentSplit: 1,
            openDebug: true,
            oStyle: {
              borderSelect: '#000',
            }
          })
          let index = that.playerObj.currentWindowIndex, playURL = code, mode = 1;
          that.playerObj.JS_Play(playURL, { playURL, mode }, index).then(
            () => { console.log('realplay success') },
            e => { console.error(e) }
          )
      })
			// })
    },
  }
})
</script>

<style scoped>
  .page-modal {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1019;
  }
  .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1020;
    background-color: pink;
  }
  .big_right-center-z{
    width:12.03125rem;
    /* height:5.45rem; */
    height:6.849609375rem;
    background-image: url("../../assets/device/videoBg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 7.5px 7.5px;
    z-index: 1021;
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -60%);
  }
  .video-height{
    /* height:5.2625rem; */
    height:6.662109375rem;
    width:11.84375rem;
    position: relative;
  }
  .video-width-fill{
    width: 100%;
    height: 100%;
  object-fit: fill
}
.mask-close{
  position: absolute;
  top:2.24rem;
  right: 5.98rem;
  border-color: white;
  border-width: 2px;
  border-style: solid;
  z-index: 2100;
  width:0.4rem;
  height:0.4rem;
  cursor: pointer;
}
</style>