<template>
  <div class="pagination-container">
    <el-pagination
      class="el-pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      @prev-click="handlePrevClick"
      @next-click="handleNextClick"
      :current-page.sync="currentPage"
      :page-size="page_size"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>
    <!-- <span class="btn">确定</span> -->
  </div> 
</template>
<script>
export default{
  props:{
    total:{
      required:true,
    },
    page:{
      type:Number,
      default:1,
    },
    limit:{
      type:Number,
      default:10,
    },
    pageSizes:{
      type:Array,
      default(){
        return[10,20,30,50]
      }
    },
    layout:{
      type:String,
      default: "total, prev, pager, next",
    },
    background: {
      type: Boolean,
      default: false,
    },
    autoScroll: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    sizes: {
      type: String,
    },
  },
  data(){
    return{
      inputPage:''
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      },
    },
    page_size: {
      get() {
        return this.limit;
      },
      set(val) {
        this.$emit("update:limit", val);
      },
    },
    totalNum() {
      if (typeof this.total !== "Number") {
        return parseFloat(this.total);
      } else {
        return this.total;
      }
    },
    allPage() {
      return Math.ceil(this.totalNum / this.page_size);
    },
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("pagination", { page: this.currentPage, limit: val });
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    handleCurrentChange(val) {
      this.$emit("pagination", val, this.page_size);
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    handleChangeSize(size) {
      this.$emit("update:limit", size);
    },
    handleNextClick(){

    },
    handlePrevClick(){

    }
  },
}
</script>

<style scoped>
.pagination-container{
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 4.875rem;
  margin-top: 15px;
}
.el-pagination{
  background-color: transparent;
  /* width: 4.875rem; */
}
.btn{
  background: #00b1d1;
  line-height: 0.3rem;
  padding: 0 0.1rem;
  border-radius: 5px;
  margin: 0 0.1rem;
  cursor: pointer;
  color:white;
  white-space: nowrap;
}
.inputNumber{
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.deviceEquipmentAlarmPage-pagination{
  color:white;
  height:0.5rem;
  display: flex;
  align-items: center;
}
.pagination-container >>>.el-pagination__total {
  color: #fff;
}
.pagination-container >>>.el-pager li {
  min-width: 27.5px;
}
</style>