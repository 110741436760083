<template>
  <div class="page-modal" v-if="!showInfo1">
    <div class="mask" @click.stop="showInfo1 = true"></div>
    <div class="redBox" >
      <div class="redBox-top">
        <div class="redBox-top-name">{{info1.msg}}</div>
        <img 
          src="@/assets/close-2.png" 
          class="button" 
          @click="showInfo1 = true"
        />
        <!-- <div class="button" @click="showInfo1 = false"></div> -->
      </div>
      <div class="content">
        <div class="content-son">
          <div class="title">设备编号</div>
          <div class="message">{{info1.eventId}}</div>
        </div>
        <div class="content-son" v-if="info1.location!=-1">
          <div class="title">位置</div>
          <div class="message">{{info1.location}}</div>
        </div>
        <div class="content-son">
          <div class="title">抓拍时间</div>
          <div class="message">{{info1.happenTime}}</div>
        </div>
        <div class="content-son" v-if="info1.license!=-1">
          <div class="title">车牌号</div>
          <div class="message">{{info1.license}}</div>
        </div>
        <!-- <div class="bottom-video" id="player_3"></div> -->
        <div class="content-img">
          <div class="title">抓拍照片</div>
          <div v-if="picture.length>0">
            <el-image 
              v-for="(item, index) in picture"
              class="content-img-son"
              :src="item" 
              :preview-src-list="picture"
              :z-index="3000"
            >
            </el-image>
          </div>
          <span v-else style="color: #fff">暂无</span>
        </div>
        <!-- <div class="content-son">
          <div class="title">车牌号</div>
          <div class="message">浙C·88888</div>
        </div>
        <div class="content-son">
          <div class="title">联系方式</div>
          <div class="message">李振兴  15058785555</div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";


const app_key = "25231058";
const app_secret = "PEP4K8hiZQAGGjmJqzvA";

const content_type="application/json";//类型
const accept="*/*" ;//accept
export default({
  // props:['info1','value'],
  data(){
    return{
      showInfo1:true,
      info1:{},
      playerObj:null,
      picture:[]
    }
  },
  created(){

  },
  mounted(){
    document.addEventListener("click",(e) =>{
      // if(!this.$el.contains(e.target) && e.target.className !='iconImgCamera'&& e.target.className !='equipmentAlarmList'){
      //   this.showInfo1 =true
      // }
    });
  },
  watch: {
    
  },
  methods:{
    init(data){
      this.showInfo1 = false
      this.info1 = data;
      console.log(data)
      // if(this.info1.picUrls){
      //   this.getPicture();
      //   return
      // }
      this.picture = this.info1.img;
    },
    getPicture(){
      let that = this;
      const url = "/api/getPicture?picUri="+this.info1.picUrls+'&svrIndexCode='+this.info1.svrIndexCode;
			const time = + new Date();
			const sign_str = `POST\n${accept}\n${content_type}\nx-ca-key:${app_key}\nx-ca-timestamp:${time}\n${url}`;
			const hash = this.$CryptoJS.HmacSHA256(sign_str, app_secret);
			const base64 = hash.toString(this.$CryptoJS.enc.Base64);
      axios({
      url: process.env.NODE_ENV === 'production' ? `http://127.0.0.1:1000${url}` : `/api${url}`,
      method: "get",
      withCredentials: false,
      headers: {
			  "Accept": accept,
			  "Content-Type": content_type,
        "X-Ca-Key": app_key,
			  "X-Ca-Signature": base64,
			  "X-Ca-Timestamp": time,
        "X-Ca-Signature-Headers": "x-ca-key,x-ca-timestamp",
			  "Access-Control-Allow-Origin": "*",
			  "Access-Control-Allow-Headers": "content-type",
      },
      }).then((res) => {
        console.log(res)
        that.picture = [res.data.data.picUrl]
			})
    }
  }
})
</script>

<style scoped lang="scss">
.page-modal {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1019;
      /* 滚动条样式 */
    /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
    ::-webkit-scrollbar {
      width: 4px;
      height: 10px;
      //   background-color: #00d8ff;
    }
    /*定义滚动条轨道 内阴影+圆角*/
    ::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px #08101d;
      // border-radius: 10px;
      background-color: #0b1933;
    }

    /*定义滑块 内阴影+圆角*/
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 6px #152847;
      background-color: #00d8ff;
    }
  }
  .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1020;
    background-color: pink;
  }
  .redBox{
    background-image: url("../../assets/device/redBox.png");
    background-size:100% 100%;
    width:5.4rem;
    height: 4.6rem;
    position:absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -85%);
    z-index: 100000;
  }
  .redBox-top{
    height:0.6rem;
    width:5.4rem;
    padding-left:0.325rem;
    padding-right:0.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .button{
    width: 0.3rem;
    height: 0.3rem;
    cursor: pointer;
  }
  .content{
    height: calc(100% - 0.6rem);
    overflow-x: hidden;
    padding:0.3rem 0.3rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  
  }
  .content-son{
    width:49%;
    display: flex;
    flex-direction: column;

  }
  .title{
    color:#D2D3ED;
    font-family: MicrosoftYaHei;
    font-size:0.175rem;
    margin-bottom:0.1375rem;
  }
  .content-img-son{
    width:0.9rem;
    height:0.9rem;
    margin-right:0.25rem;
    margin-bottom:0.15rem;
  }
  .content-img{
    width:5.4rem;
    height:1.2125rem;
    display: flex;
    flex-direction: column;
    margin-bottom:0.3rem;
  }
  .redBox-top-name{
    color:#F3FCFF;
    font-size:0.2rem;
  }
  .message{
    font-size:0.175rem;
    font-family: 'MicrosoftYaHei-Bold';
    color:#F3FCFF;
    margin-bottom:0.3rem;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: normal !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .bottom-video{
    width:5.2rem;
    height:2.2rem;
  }
  .bottom-video-z{
    width:100%;
    height:100%;
    object-fit: fill
  }
</style>