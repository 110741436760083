<template>
  <div class="info" v-if="!showInfo2">
    <i class="close" @click="showInfo2 = true"
      ><img src="@/assets/close-2.png" alt=""
    /></i>
    <div class="listBox">
      <div class="listUl">
        <h6 @click="handleClick1()" :class="show1 ? 'act' : ''">
          设备信息
        </h6>
        <ul v-show="show1">
          
          <li>
            <span>设备名称</span>
            <p>门闸</p>
          </li>
          <li>
            <span>设备型号</span>
            <p>设备新号</p>
          </li>
          <li>
            <span>保修状态</span>
            <p>完成</p>
          </li>
          <li>
            <span>所属公司</span>
            <p>公司啊。....................</p>
          </li>
        </ul>
      </div>
      <div class="listUl">
        <h6  :class="show2 ? 'act' : ''">
          维保任务
        </h6>
        <div class="scrollbar">
          <template v-if="show2">
            <!-- <ul
              style="border-bottom: solid 1px rgba(11, 96, 120, 1)"
              v-for="(t,i) in gateMessage"
              :key="i"
            >
              <li>
                <span>任务名称</span>
                <p>{{t.taskName}}</p>
              </li>
              <li>
                <span>状态</span>
                <p>{{ t.status ? "完成" : "未完成" }}</p>
              </li>
              <li>
                <span>开始日期</span>
                <p>{{ t.startDate }}</p>
              </li>
              <li>
                <span>执行人</span>
                <p>{{ t.executor }}</p>
              </li>
            </ul> -->
          </template>
        </div>
      </div>
      <div class="listUl">
        <h6 @click="handleClick3()" :class="show3 ? 'act' : ''">
          维保标准与方法
        </h6>
        <div v-show="show3" class="text">
          <span> 维保标准 </span>
          <p>
            甲级维护应具备的条件主要是：设备及附件完整、可靠、清洁、
            坚固、周围无垃圾；合理使用设备、润滑材料、器具、管线及
            “五定”贯彻良好；各项规章制度及记录准确、完整。乙级维护
            的条件是：设备及附件基本完整，运行可靠，清洁，调整好，紧
            固好，但有个别部位未达要求；润滑工作基本达到要求，但有轻
            微油量不足及油质不洁；各项制度、记录基本得到贯彻。未达到
            乙级维护标准者均为丙级维护
          </p>
          <span>维保方法</span>
          <p>
            只要是设备，就有可能出现故障！出现故障，就离不开维修。不怕出故障，就怕没方法。没有解决不了的故障，关键是怎样去思考，怎样去判断，用什么方法快速地解决！任何人只要通过学习和训练，都能获得这种能力。掌握了方法，就等于掌握了通往解决问题之门的钥匙！本书紧密结合现实生活和工作中的案例，系统性地阐述了十大维修原则，创造性地论述了十大维修方法，概
            括性地总结了十大维修经验，并有针对性地提出了解决故障的十
            大问题。本书语言生动，配图精彩，案例浅显易懂，专业性与可
            读性并重，是一本普及性的大众读本，既可作为维修人员的教材，
            又可作为普通读者学习维修技术知识的入门书。积财千万，不如
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info2','value'],
  data() {
    return {
      showInfo2: true,
      show1: false,
      show2: false,
      show3: false,
    }
  },
  created() {
  },
  // filters: {
  //   warrantyStatus(val) {
  //     switch (val) {
  //       case 1:
  //         return "已过保";
  //       case 2:
  //         return "质保时间内";
  //       case 3:
  //         return "无";
  //     }
  //   },
  // },
  mounted() {
    //点击其他区域关闭下拉菜单
    document.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target) && e.target.className != 'iconImgGate') {
        this.showInfo2 = true
      }
    });
  },
  watch: {
    // info2(val) {
    //   this.showInfo2 = true
    // }
  },
  methods: {
    init(){
      this.showInfo2 = true
      console.log('showInfo2showInfo2showInfo2showInfo2')
    },
     handleClick1() {
      this.show1 = !this.show1;
      if (this.show1) {
       this.show2 = false;
        this.show3 = false;
      }
    },
    handleClick2() {
      this.show2 = !this.show2;
      if(this.show2) {
        this.getEquipmenyTask2()
      }
      if (this.show2) {
        this.show1 = false;
        this.show3 = false;
      }
    },
    handleClick3() {
      this.show3 = !this.show3;
      if (this.show3) {
        this.show2 = false;
        this.show1 = false;
      }
    },
    // async getEquipmenyTask2() {
    //   let data = {
    //     equipmentId: this.info2.equipmentId,
    //   };
    //   let res = await this.$api.device.getEquipmenyTask2(data);
    //   // if (res.code == 200) {
    //   //   this.taskList = res.data.dataList;
    //   // }
    // },
  }
};
</script>
<style lang="scss" scoped>
    .info {
      position: fixed;
      right: calc(50% - 2.75rem);
      z-index: 20001;
      top: 2rem;
      width: 5.5rem;
      height: 7rem;
      background-image: url("../../assets/device/devbg.png");
      background-size: 100% 100%;
      padding-top: .15rem;
      .close {
        position: absolute;
        right: 0.2rem;
        top: 0.1rem;
        cursor: pointer;
        img {
          width: 0.3rem;
          height: 0.3rem;
        }
      }
      .listBox {
        height: 9rem;
        margin-top: 0.2rem;
        overflow: auto;
      }
      .listUl {
        width: 100%;
        padding-left: 0.06rem;
        h6 {
          height: 0.7rem;
          background-image: url("../../assets/video/videoT.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          cursor: pointer;
          font-weight: 700;
          font-size: 0.2rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.25rem;
          color: #fff;
          img {
            width: 0.15rem;
            height: 0.15rem;
          }
          &::before {
            position: absolute;
            right: 0.4rem;
            content: "";
            background-image: url("../../assets/home/zhankai.png");
            width: 0.15rem;
            height: 0.15rem;
            background-size: 100% 100%;
          }
          &.act::before {
            background-image: url("../../assets/home/zhankai1.png");
          }
        }
        > ul {
          width: 100%;
          padding: 0.25rem 0rem 0.3rem 0.3rem;
          display: flex;
          flex-wrap: wrap;
          > li {
            width: calc(50% - 0.1rem);
            height: 0.75rem;
            text-align: left;
            font-size: 0.2rem;
            position: relative;
            color: #6793b8;
            letter-spacing: 0.01rem;
            margin-bottom: 0.1rem;
            span {
              color: #d2d3ed;
              font-size: 0.2rem;
              line-height: 2;
            }
            p {
              color: #fff;
              font-size: 0.2rem;
              line-height: 1.5;
              font-weight: bold;
            }
            &:nth-child(odd) {
              width: 35%;
            }
            &:nth-child(even) {
              width: 65%;
            }
          }
        }
        > .scrollbar {
          margin-right: 0.24rem;
          max-height: 4.5rem;
          overflow: auto;
          ul {
            width: 100%;
            padding: 0.25rem 0rem 0.3rem 0.3rem;
            display: flex;
            flex-wrap: wrap;
            margin-right: 0.2rem;
            > li {
              width: calc(50% - 0.1rem);
              height: 0.75rem;
              text-align: left;
              font-size: 0.2rem;
              position: relative;
              color: #6793b8;
              letter-spacing: 0.01rem;
              margin-bottom: 0.1rem;
              span {
                color: #d2d3ed;
                font-size: 0.2rem;
                line-height: 2;
              }
              p {
                color: #fff;
                font-size: 0.2rem;
                line-height: 1.5;
                font-weight: bold;
              }
              &:nth-child(odd) {
                width: 35%;
              }
              &:nth-child(even) {
                width: 65%;
              }
            }
          }
        }
        .text {
          height: 4.3rem;
          overflow: auto;
          color: #fff;
          padding: 0.3rem 0.3rem 0 0.2rem;
          span {
            color: rgba(210, 211, 237, 1);
            margin-bottom: 0.1rem;
            display: block;
          }
          p {
            margin-bottom: 0.2rem;
          }
        }
      }
    }
</style>