<template>
  <dv-full-screen-container class="bg">
    <Header></Header>
    <Footer></Footer>
    <div class="left">
      <div>
        <div class="top" v-show="numberShow">
          <h5>设备数量</h5>
          <img
            src="@/assets/home/zhankai.png"
            alt=""
            class="imgs"
            @click="handleNumber"
          />
          <ul class="deviceUl">
            <li
              v-for="(item, index) in deviceList"
              :key="index"
              :style="{ backgroundImage: 'url(' + item.src + ')' }"
            >
              <div class="pBox">
                <p class="p1" :style="{ color: item.color }">
                  <countTo
                    :startVal="startVal"
                    :endVal="item.sum ? item.sum : 0"
                    :duration="3000"
                    style="font-size: 0.4rem; font-weight: 700; opacity: 1"
                  ></countTo>
                  <span>个</span>
                </p>
                <p class="p2">{{ item.device }}</p>
              </div>
            </li>
          </ul>
        </div>
        <div
          class="top"
          v-show="!numberShow"
          style="height: 0.6125rem"
          :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
        >
          <h5>设备数量</h5>
          <img
            src="@/assets/home/zhankai.png"
            alt=""
            class="imgs"
            @click="handleNumber"
            style="transform: rotate(180deg)"
          />
        </div>
      </div>
      <div>
        <div class="bottom" v-show="operationShow">
          <h5>特种设备维护情况</h5>
          <img
            src="@/assets/home/zhankai.png"
            alt=""
            class="imgs"
            @click="handleOperation"
          />
          <Echart :options="optionState" height="3.2rem"></Echart>
          <div class="devices">
            <div class="noDevice"><span></span>未完成</div>
            <div class="yesDevice"><span></span>已完成</div>
          </div>
        </div>
        <div
          class="bottom"
          v-show="!operationShow"
          style="height: 0.67rem"
          :style="{ backgroundImage: 'url(' + imgSrc2 + ')' }"
        >
          <h5>特种设备维护情况</h5>
          <img
            src="@/assets/home/zhankai.png"
            alt=""
            class="imgs"
            @click="handleOperation"
            style="transform: rotate(180deg)"
          />
        </div>
      </div>
    </div>
    <div  class="right">
      <div>
        <div class="right-topz"  v-show="eventShowz">
          <div class="right-top-liz-margin">
            <h5>事件统计</h5>
            <img src="@/assets/home/zhankai.png" alt="" class="imgs"
            @click="statisticsShowz"
            />
          </div>
          <div class="right-top-z">
            <div
              class="right-top-liz"
              v-for="(item,indexs) in list2" :key="indexs"
            >
              <img  :src="item.img" alt="" class="right-top-imgz" />
              <div class="right-top-rightz">
                <div class="big-wt">
                  <el-tooltip effect="light" :content="item.wt+'次'" placement="top-end">
                    <span class="sm-num">{{item.wt}}</span>
                  </el-tooltip>
                  <span class="sm-wt">次</span>
                </div>
                <div class="sm-blue">{{item.name}}</div>
              </div>
            </div>
          </div>
        </div>
        <div 
          class="right-topz" 
          v-show="!eventShowz"
          style="height:0.67rem"
          :style="{backgroundImage:'url(' +imgSrc2 +')'}"
        >
          <h5>事件统计</h5>
          <img 
            src="@/assets/home/zhankai.png"
            alt=""
            class="imgs"
            @click="statisticsShowz"
            style="transform:rotate(180deg)"
          />
        </div>
      </div>
      <div>
        <div class="right-centerz" v-show="wisdomzShow">
          <div class="relative select-view">
            <h5>实时监控</h5>
            <!-- <Selects
                class="select-box"
                v-model="video_list_name"
                :list="video_list"
                ref="Selectsz"
                @input="handleVideo"
            /> -->
            <div class="select-box">
              <el-cascader
                v-model="video_list_name"
                :options="video_list"
                ref="cascader"
                :props="{children:'child', value:'name', label:'name'}"
                @change="handleVideo"
              ></el-cascader>
            </div>
          </div>
          <div class="right-center-z" v-if="video_success">
            <div v-loading="video_loading" id="player_1" style="width:379px;height:212px" @click="videoElarge"></div>
            <div class="controls" >
              <img class="navigation-left" src="../../assets/device/left-button.png" @click.stop="backOff"/>
              <img class="navigation-right" src="../../assets/device/right-button.png" @click.stop="forward"/>
            </div>
          </div>
          <div class="right-center-z" v-if="!video_success">
            <div class="error-tip">
			      	<p class="s1">暂</p>
			      	<p class="s2">无</p>
			      	<p class="s3">视</p>
			      	<p class="s4">频</p>
			      	<p class="s5">.</p>
			      	<p class="s6">.</p>
			      	<p class="s7">.</p>
			      	<p class="s8">.</p>
			      </div>
          </div>
          <span class="videoText" v-if="camera_list.length>0&&video_success">{{camera_list[camera_list_index].cameraName}}</span>
        </div>
        <div 
          class="right-centerz relative" 
          v-show="!wisdomzShow"
          style="height:0.6125rem;"
          :style="{backgroundImage:'url(' +imgSrc+')'}"
        >
          <h5>智慧监控</h5>
          <img
            src="@/assets/home/zhankai.png"
            alt=""
            class="imgs"
            @click="wisdomz"
            style="transform:rotate(180deg)"
          />
        </div>
      </div>
      <div>
        <div class="right-bottomZ" v-show="equipmentAlarmShow">
          <div class="relative">
            <h5>设备报警</h5>
            <img src="@/assets/home/zhankai.png" alt="" class="imgs"
            @click="equipmentAlarmShowClick"/>
          </div>
          <div class="equipmentAlarmList-fd" v-loading="event_loading">
            <div class="overflow-width">
              <div 
                class="equipmentAlarmList"
                v-for="(item,eal) in equipmentAlarmListData"
                :key=eal
                @click="alarmEventPopUp(item)"
              >
                <div class="equipmentAlarmList-top">
                  <div class="eAtitle">{{item.title}}</div>
                  <div class="eAtime">{{item.time}}</div>
                </div>
                <div class="equipmentAlarmList-bottom">
                  <div class="spanText">{{item.place}}</div>
                  <div class="spanText">{{item.message}}</div>
                </div>
              </div>
            </div>
          </div>
          <DeviceEquipmentAlarmPage 
            class="DeviceEquipmentAlarmPage"
            @pagination="handlePagination"
            :page.sync="page"
            :limit.sync="page_size"
            :total="total"
          />
        </div>
        <div style="height:0.6125rem;" 
          v-show="!equipmentAlarmShow" 
          :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
          class="right-centerz" 
        >
          <div class="relative">
            <h5>设备报警</h5>
            <img
              src="@/assets/home/zhankai.png"
              class="imgs"
              alt=""
              @click="equipmentAlarmShowClick"
              style="transform: rotate(180deg)"
            />
          </div>
        </div>
      </div>
      <!-- <div class="right" :class="maintainShow ? '' : 'hidden'">
        <div @click="handleMaintain">
          <h5>特种设备</h5>
          <img src="@/assets/home/zhankai.png" alt="" class="imgs" />
        </div>
        <ul>
          <li
            v-for="(item, index) in list1"
            :key="index"
            @click="handleShow(item)"
          >
            <span>【{{ item.name }}】</span>
            {{
              item.taskList.length > 0
                ? "上次维保时间 " + item.taskList[0].startDate
                : ""
            }}
            <i><img src="@/assets/device/r-icon.png" alt="" /></i>
          </li>
          <li
            v-for="(item, index) in list"
            :key="index"
            @click="handleShow(item)"
          >
            <span>【{{ item.name }}】</span>
            {{
              item.taskList.length > 0
                ? "上次维保时间 " + item.taskList[0].startDate
                : ""
            }}
            <i><img src="@/assets/device/r-icon.png" alt="" /></i>
          </li>
        </ul>
      </div> -->
    </div>
    <div class="controlPanel-z">
      <div 
        :key="cpz"
        v-for="(item,cpz) in cpListz"
        v-show="cpbuttonShow"
        @click="equipmentShowClick(item, cpz)"
      >
        <div 
          class="cpButton-st" :class="item.type?'nullValue':'cpButton'"
        >
          <div :class="item.imgz"></div>
          <!-- <div :class="cpBntextz" class="cptextz">{{item.textz}}</div> -->
          <div :class="item.type?'nullValue':'cptextz'" class="cptextz-st">{{item.textz}}</div>
        </div>
      </div>
    </div>
    <div class="controlPanel">
      <div :class="cpButton5" class="cpButton-st" @click="cpbuttonShowClick">
        <div :class="imgsrc5" class="imgsrc5-st"></div>
    </div>
    </div>
    <div class="urlBox">
      <div class="li1" @click.stop="handleSecurity(2)">园区消防</div>
      <!-- <div class="li1" @click.stop="handleSecurity(1)">实时监控</div> -->
      <div class="li2 isactive" @click.stop="handleSecurity(0)">智慧运维</div>
    </div>
    <div class="positionz" v-show="positionShow1">
      <div
        class="positionImgCamera"
        :style="{
          left:item.left,
          top:item.top,
        }"
        v-for="(item,index1) in positionImgCameraLocation"
        :key="index1"
      >
        <img
          v-show="!item.showInfo1"
          :src="item.showinfo1imgsrc"
          @click="handleShow(item, item.flag)"
          :class="item.showinfo1imgclass"
          alt=""
        />
        <img 
          v-show="item.showInfo1"
          :src="item.showinfo1imgsrc"
          @click="handleShow(item, item.flag)"
          :class="item.showinfo1imgclass"
          alt=""
        />
      </div>
    </div>
    <div class="positionz" v-show="positionShow2">
      <div
        class="positionImgGate"
        :style="{
          left:item.left,
          top:item.top,
        }"
        v-for="(item,index2) in positionImgGate"
        :key="index2"
      >
        <img
          v-show="!item.showInfo2"
          src="@/assets/device/newIcon1.png"
          @click="handleShow(item, 3)"
          class="iconImgGate"
          alt=""
        />
        <img
          v-show="item.showInfo2"
          src="@/assets/device/newIcon1.png"
          @click="handleShow(item, 3)"
          class="iconImgGate"
          alt=""
        />

      </div>
    </div>
    <div class="positionz" v-show="positionShow3">
      <div
        class="positionImgSpeedDomeCameras"
        :style="{
          left:item.left,
          top:item.top
        }"
        v-for="(item,index3) in positionImgSpeedDomeCameras"
        :key="index3"
      >
        <img 
          v-show="!item.showInfo"
          src="@/assets/device/newIcon2.png"
          @click="handleShow(item, 4)"
          class="iconImgSpeedDomeCameras"
          alt=""
        >
        <img
          v-show="item.showInfo"
          class="iconImgSpeedDomeCameras"
          @click="handleShow(item, 4)"
          src="@/assets/device/newIcon2.png"
        />

      </div>
    </div>
    <div class="position" v-show="positionShow4">
      <div
        class="positionImg11"
        :style="{
          left: item.left,
          top: item.top,
          zIndex: item.showInfo ? '1011' : '1010',
        }"
        v-for="(item, idx) in list"
        :key="idx"
      >
      <!-- class="iconImg" -->
        <img
          v-show="!item.showInfo"
          src="@/assets/device/newIcon3.png"
          @click="handleShow(item, 1)"
          class="iconIMG5"
          alt=""
        />
        <img
          v-show="item.showInfo"
          src="@/assets/device/newIcon3.png"
          @click="handleShow(item, 1)"
          class="iconIMG5"
          alt=""
        />
        <!-- <div class="info" v-if="item.showInfo">
          <i class="close" @click="item.showInfo = false"
            ><img src="@/assets/close-2.png" alt=""
          /></i>
          <div class="listBox">
            <div class="listUl">
              <h6 @click="handleClick1(item)" :class="item.show1 ? 'act' : ''">
                设备信息
              </h6>
              <ul v-show="item.show1">
                <li>
                  <span>设备名称</span>
                  <p>{{ item.info.equipmentName }}</p>
                </li>
                <li>
                  <span>设备型号</span>
                  <p>{{ item.info.equipmentNumber }}</p>
                </li>
                <li>
                  <span>保修状态</span>
                  <p>{{ item.info.warrantyStatus | warrantyStatus }}</p>
                </li>
                <li>
                  <span>所属公司</span>
                  <p>{{ item.info.message }}</p>
                </li>
              </ul>
            </div>
            <div class="listUl">
              <h6 @click="handleClick2(item)" :class="item.show2 ? 'act' : ''">
                维保任务
              </h6>
              <div class="scrollbar">
                <template v-if="item.show2">
                  <ul
                    style="border-bottom: solid 1px rgba(11, 96, 120, 1)"
                    v-for="(t, i) in item.taskList"
                    :key="i"
                  >
                    <li>
                      <span>任务名称</span>
                      <p>{{ t.taskName }}</p>
                    </li>
                    <li>
                      <span>状态</span>
                      <p>{{ t.status ? "完成" : "未完成" }}</p>
                    </li>
                    <li>
                      <span>开始日期</span>
                      <p>{{ t.startDate }}</p>
                    </li>
                    <li>
                      <span>执行人</span>
                      <p>{{ t.executor }}</p>
                    </li>
                  </ul>
                </template>
              </div>
            </div>
            <div class="listUl">
              <h6 @click="handleClick3(item)" :class="item.show3 ? 'act' : ''">
                维保标准与方法
              </h6>
              <div v-show="item.show3" class="text">
                <span> 维保标准 </span>
                <p>
                  甲级维护应具备的条件主要是：设备及附件完整、可靠、清洁、
                  坚固、周围无垃圾；合理使用设备、润滑材料、器具、管线及
                  “五定”贯彻良好；各项规章制度及记录准确、完整。乙级维护
                  的条件是：设备及附件基本完整，运行可靠，清洁，调整好，紧
                  固好，但有个别部位未达要求；润滑工作基本达到要求，但有轻
                  微油量不足及油质不洁；各项制度、记录基本得到贯彻。未达到
                  乙级维护标准者均为丙级维护
                </p>
                <span>维保方法</span>
                <p>
                  只要是设备，就有可能出现故障！出现故障，就离不开维修。不怕出故障，就怕没方法。没有解决不了的故障，关键是怎样去思考，怎样去判断，用什么方法快速地解决！任何人只要通过学习和训练，都能获得这种能力。掌握了方法，就等于掌握了通往解决问题之门的钥匙！本书紧密结合现实生活和工作中的案例，系统性地阐述了十大维修原则，创造性地论述了十大维修方法，概
                  括性地总结了十大维修经验，并有针对性地提出了解决故障的十
                  大问题。本书语言生动，配图精彩，案例浅显易懂，专业性与可
                  读性并重，是一本普及性的大众读本，既可作为维修人员的教材，
                  又可作为普通读者学习维修技术知识的入门书。积财千万，不如
                </p>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div
        class="positionImg11"
        :style="{
          left: item.left,
          top: item.top,
          zIndex: item.showInfo ? '1011' : '1010',
        }"
        v-for="(item, idx) in list1"
        :key="idx"
      >
        <img
          v-show="item.showInfo"
          src="@/assets/device/icon5.png"
          @click="handleShow(item, 1)"
          alt=""
          class="iconIMG5"
        />
        <img
          v-show="!item.showInfo"
          src="@/assets/device/icon5.png"
          @click="handleShow(item, 1)"
          alt=""
          class="iconIMG5"
        />
        <!-- <div class="info" v-if="item.showInfo">
          <i class="close" @click="item.showInfo = false"
            ><img src="@/assets/close-2.png" alt=""
          /></i>
          <div class="listBox">
            <div class="listUl">
              <h6 @click="handleClick1(item)" :class="item.show1 ? 'act' : ''">
                设备信息
              </h6>
              <ul v-show="item.show1">
                <li>
                  <span>设备名称</span>
                  <p>{{ item.info.equipmentName }}</p>
                </li>
                <li>
                  <span>设备型号</span>
                  <p>{{ item.info.equipmentNumber }}</p>
                </li>
                <li>
                  <span>保修状态</span>
                  <p>{{ item.info.warrantyStatus | warrantyStatus }}</p>
                </li>
                <li>
                  <span>所属公司</span>
                  <p>{{ item.info.message }}</p>
                </li>
              </ul>
            </div>
            <div class="listUl">
              <h6 @click="handleClick2(item)" :class="item.show2 ? 'act' : ''">
                维保任务
              </h6>
              <div class="scrollbar">
                <template v-if="item.show2">
                  <ul
                    style="border-bottom: solid 1px rgba(11, 96, 120, 1)"
                    v-for="(t, i) in item.taskList"
                    :key="i"
                  >
                    <li>
                      <span>任务名称</span>
                      <p>{{ t.taskName }}</p>
                    </li>
                    <li>
                      <span>状态</span>
                      <p>{{ t.status ? "完成" : "未完成" }}</p>
                    </li>
                    <li>
                      <span>开始日期</span>
                      <p>{{ t.startDate }}</p>
                    </li>
                    <li>
                      <span>执行人</span>
                      <p>{{ t.executor }}</p>
                    </li>
                  </ul>
                </template>
              </div>
            </div>
            <div class="listUl">
              <h6 @click="handleClick3(item)" :class="item.show3 ? 'act' : ''">
                维保标准与方法
              </h6>
              <div v-show="item.show3" class="text">
                <span> 维保标准 </span>
                <p>
                  甲级维护应具备的条件主要是：设备及附件完整、可靠、清洁、
                  坚固、周围无垃圾；合理使用设备、润滑材料、器具、管线及
                  “五定”贯彻良好；各项规章制度及记录准确、完整。乙级维护
                  的条件是：设备及附件基本完整，运行可靠，清洁，调整好，紧
                  固好，但有个别部位未达要求；润滑工作基本达到要求，但有轻
                  微油量不足及油质不洁；各项制度、记录基本得到贯彻。未达到
                  乙级维护标准者均为丙级维护
                </p>
                <span>维保方法</span>
                <p>
                  只要是设备，就有可能出现故障！出现故障，就离不开维修。不怕出故障，就怕没方法。没有解决不了的故障，关键是怎样去思考，怎样去判断，用什么方法快速地解决！任何人只要通过学习和训练，都能获得这种能力。掌握了方法，就等于掌握了通往解决问题之门的钥匙！本书紧密结合现实生活和工作中的案例，系统性地阐述了十大维修原则，创造性地论述了十大维修方法，概
                  括性地总结了十大维修经验，并有针对性地提出了解决故障的十
                  大问题。本书语言生动，配图精彩，案例浅显易懂，专业性与可
                  读性并重，是一本普及性的大众读本，既可作为维修人员的教材，
                  又可作为普通读者学习维修技术知识的入门书。积财千万，不如
                </p>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <Dialog :info="info" ref="Dialog"  />
    <Dialog1 :info2="info2" ref="Dialog1" />
    <Dialog2 :info1="info1" ref="Dialog2" />
    <Dialog3 :info3="info3" ref="Dialog3" />
    <VideoModal ref="videoModal"></VideoModal>

    <!--<Dialog :info="info" v-model="showInfo" /> 
    <Dialog1 :info2="info2"  v-model="showInfo2"/>
    <Dialog2 :info1="info1" v-model="showInfo1"/>
    <Dialog3 :info3="info3" v-model="showInfo3"/> -->
    <!-- <div class="weather">
      <div>阴</div>
      <img src="../../assets/device/weather-img.png" alt="" class="weather-img"/>
      <div>29℃</div>
    </div> -->
  </dv-full-screen-container>
</template>

<script>
import axios from "axios";
import Echart from "@/common/echart";
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
import countTo from "vue-count-to";
import Dialog from "./dialog.vue";
import Selects from "@/components/Select/index4.vue";
import Dialog1 from "./gate.vue";
import Dialog2 from "./camera.vue";
import Dialog3 from "./SpeedDomeCameras.vue"
import VideoModal from "./VideoModal"
import DeviceEquipmentAlarmPage from "./deviceEquipmentAlarmPage.vue"

const app_key = "25231058";
const app_secret = "PEP4K8hiZQAGGjmJqzvA";

const content_type="application/json";//类型
const accept="*/*" ;//accept
export default {
  components: {
    Header,
    Footer,
    Echart,
    countTo,
    Dialog,
    Selects,
    Dialog1,
    Dialog2,
    Dialog3,
    VideoModal,
    DeviceEquipmentAlarmPage
  },
  data() {
    return {
      gateMessage:[
        {
          taskList:'维修任务',
          status:true,
          startDate:'2022-12-12',
          executor:'赵仁鹏'
        },
        {
          taskList:'维修任务',
          status:false,
          startDate:'2022-12-12',
          executor:'赵仁鹏'
        },
      ],
      positionImgCameraLocation:[
        {
          left: "41%",
          top: "21%",
          showinfo1imgsrc:require('@/assets/device/newIcon.png'),
          showinfo1imgclass:'iconImgCamera',
          flag:-1,
          event:{}
        },
        {
          left: "58%",
          top: "32%",
          showinfo1imgsrc:require('@/assets/device/newIcon.png'),
          showinfo1imgclass:'iconImgCamera',
          flag:-1,
          event:{}
        },
        {
          left: '62%',
          top: '27%',
          showinfo1imgsrc:require('@/assets/device/newIcon.png'),
          showinfo1imgclass:'iconImgCamera',
          flag:-1,
          event:{}
        },
        {
          left: '49%',
          top: '55%',
          showinfo1imgsrc:require('@/assets/device/newIcon.png'),
          showinfo1imgclass:'iconImgCamera',
          flag:-1,
          event:{}
        },
        {
          left: '29%',
          top: '41%',
          showinfo1imgsrc:require('@/assets/device/newIcon.png'),
          showinfo1imgclass:'iconImgCamera',
          flag:-1,
          event:{}
        },
      ],
      positionImgGate:[
        {
          left: "48%",
          top: "61%"
        },
        {
          left: "29%",
          top: "46%",
        }
      ],
      positionImgSpeedDomeCameras:[
        {
          left: "38%",
          top: "39%"
        },
        {
          left: "62%",
          top: "56%",
        }
      ],
      positionShow1:true,
      positionShow2:true,
      positionShow3:true,
      positionShow4:true,
      cpButton1d4:'nullValue',
      imgsrc5:'nullValue',
      cpButton5:'nullValue',
      cpbuttonShow:true,
      cpListz:[
        {
         imgz:'imgsrc1-st',
         textz:'监控',
         method:"item0",
         type:true,
        },
        {
         imgz:"imgsrc2-st",
         textz:'门闸',
         method:"item1",
         type:true,
        },
        {
         imgz:"imgsrc3-st",
         textz:'球机',
         method:"item2",
         type:true,
        },
        {
         imgz:"imgsrc4-st",
         textz:"特种",
         method:"item3",
         type:true,
        },
      ],
      equipmentAlarmListData:[],
      equipmentAlarmShow:true,
      video_list_name: [],
      video_list:[
        {
          label: "西门停车场",
          value: "西门停车场",
        },
        {
          label:"东门停车场",
          value:"东门停车场",
        },
        {
          label:"南门停车场",
          value:"南门停车场",
        },
        {
          label:"极门停车场",
          value:"极门停车场",
        },
        {
          label:"北门停车场",
          value:"北门停车场",
        },
      ],
      camera_list:[],
      camera_list_index: -1,
      show: true,
      shows: false,
      selected:'',
      iconList: [
        {
          name: "监控设备",
          color: "#00ffe5",
          src: require("../../assets/device/icon.png"),
          left: "0.28rem",
        },
        {
          name: "球机监控",
          color: "#00d8ff",
          src: require("../../assets/device/icon1.png"),
          left: "0.28rem",
        },
        {
          name: "充电桩",
          color: "#87fe90",
          src: require("../../assets/device/icon2.png"),
          left: "0.35rem",
        },
        {
          name: "门禁",
          color: "#fff17a",
          src: require("../../assets/device/icon3.png"),
          left: "0.38rem",
        },
        {
          name: "特种设备",
          color: "#ffc57a",
          src: require("../../assets/device/icon4.png"),
          left: "0.28rem",
        },
      ],
      list: [
      ],
      listz2:[

      ],
      list1: [
        {
          img: require("@/assets/device/icon5.png"),
          img2: require("@/assets/device/icon5.png"),
          showInfo: false,
          show1: true,
          show2: false,
          show3: false,
          left: "55%",
          top: "32%",
          name: "锅炉-1",
          taskList: [],
        },
      ],
      fixedPosition: {
        "1A区A1": {
          left: "56%",
          top: "47%",
        },
        "1A区A2": {
          left: "56%",
          top: "47%",
        },
        "1B区B1": {
          left: "64%",
          top: "52%",
        },
        "1B区B2": {
          left: "64%",
          top: "52%",
        },
        "2A区A1": {
          left: "61%",
          top: "40%",
        },
        "2A区A2": {
          left: "61%",
          top: "40%",
        },
        "2B区B1": {
          left: "68%",
          top: "44%",
        },
        "2B区B2": {
          left: "68%",
          top: "44%",
        },
        "3A区A1": {
          left: "66%",
          top: "34%",
        },
        "3A区A2": {
          left: "66%",
          top: "34%",
        },
        "3B区B1": {
          left: "73.5%",
          top: "38%",
        },
        "3B区B2": {
          left: "73.5%",
          top: "38%",
        },
        "4A区A1": {
          left: "34%",
          top: "32%",
        },
         "4A区A2": {
          left: "34%",
          top: "32%",
        },
        "4B区B1": {
          left: "42%",
          top: "37%",
        },
        "4B区B2": {
          left: "42%",
          top: "37%",
        },
        "5A区A1": {
          left: "39%",
          top: "26%",
        },
        "5A区A2": {
          left: "39%",
          top: "26%",
        },
        "5B区B1": {
          left: "47%",
          top: "31%",
        },
         "5B区B2": {
          left: "47%",
          top: "31%",
        },
        "6A区A1": {
          left: "22.5%",
          top: "23%",
          name: "6幢电梯",
        },
        "6A区A2": {
          left: "22.5%",
          top: "23%",
          name: "6幢电梯",
        },
        "7A区A1": {
          left: "70%",
          top: "28%",
        },
        "7A区A2": {
          left: "70%",
          top: "28%",
        },
      },
      maintainShow: false,
      operationShow: true,
      numberShow: true,
      eventShowz:true,
      wisdomzShow:true,
      videoListShow:true,
      videoActive:0,
      imgSrc: require("../../assets/home/titleBg.png"),
      imgSrc2: require("../../assets/device/titleBg2.png"),
      keepList: [
        {
          device: "【锅炉1】",
          position: "上次维护时间 (2020-12-23)",
        },
        {
          device: "【1栋西侧电梯】",
          position: "上次维护时间 (2020-12-23)",
        },
        {
          device: "【1栋北侧电梯】",
          position: "上次维护时间 (2020-12-23)",
        },
        {
          device: "【1栋南侧电梯】",
          position: "上次维护时间 (2020-12-23)",
        },
        {
          device: "【1栋东侧电梯】",
          position: "上次维护时间 (2020-12-23)",
        },
        {
          device: "【3栋西侧电梯】",
          position: "上次维护时间 (2020-12-23)",
        },
        {
          device: "【3栋北侧电梯】",
          position: "上次维护时间 (2020-12-23)",
        },
        {
          device: "【3栋南侧电梯】",
          position: "上次维护时间 (2020-12-23)",
        },
        {
          device: "【3栋东侧电梯】",
          position: "上次维护时间 (2020-12-23)",
        },
        {
          device: "【2栋西侧电梯】",
          position: "上次维护时间 (2020-12-23)",
        },
        {
          device: "【2栋北侧电梯】",
          position: "上次维护时间 (2020-12-23)",
        },
        {
          device: "【4栋南侧电梯】",
          position: "上次维护时间 (2020-12-23)",
        },
        {
          device: "【5栋东侧电梯】",
          position: "上次维护时间 (2020-12-23)",
        },
        {
          device: "【6栋西侧电梯】",
          position: "上次维护时间 (2020-12-23)",
        },
        {
          device: "【6栋北侧电梯】",
          position: "上次维护时间 (2020-12-23)",
        },
        {
          device: "【6栋南侧电梯】",
          position: "上次维护时间 (2020-12-23)",
        },
        {
          device: "【2栋东侧电梯】",
          position: "上次维护时间 (2020-12-23)",
        },
      ],
      deviceList: [
        {
          sum: "18",
          device: "特种设备",
          color: "#00E4FF",
          src: require("../../assets/device/4.png"),
        },
        {
          sum: "20",
          device: "球机",
          color: "#FFC57A",
          src: require("../../assets/device/2.png"),
        },
        {
          sum: "18",
          device: "门禁",
          color: "#FFF17A",
          src: require("../../assets/device/3.png"),
        },
        {
          sum: "14",
          device: "监控设备",
          color: "#00FFE5",
          src: require("../../assets/device/1.png"),
        },
      ],
      list2:[
        {
          img:require("@/assets/device/road.png"),
          wt:0,
          name:'占道抓拍'
        },
        {
          img:require("@/assets/device/river.png"),
          wt:0,
          name:'沿河抓拍'
        },
        {
          img:require("@/assets/device/face.png"),
          wt:0,
          name:'人脸抓拍'
        },
      ],
      // videoList:[
      //   {
      //     videoSrc:require("@/assets/device/video/mp4.mp4"),
      //   }
      // ],
      // deviceListY:[
      //   {
      //     videoName:"西门停车场"
      //   },
      //   {
      //     videoName:"东门停车场"
      //   },
      //   {
      //     videoName:"南门停车场"
      //   },
      //   {
      //     videoName:"极门停车场"
      //   },
      //   {
      //     videoName:"北门停车场"
      //   },

      // ],
      inputValuez:"",
      optionState: {
        title: {
          text: 0, //主标题文本
          subtext: "设备维护", //副标题文本
          left: "center",
          top: "45%",
          textStyle: {
            fontSize: 32,
            color: "#fff",
            align: "center",
          },
          subtextStyle: {
            fontSize: 14,
            color: "#ABDAFE",
          },
        },
        tooltip: {
          trigger: "item",
        },
        color: ["#00BAFF", "#008073"],
        series: [
          {
            type: "pie",
            radius: ["50%", "80%"],
            center: ["50%", "58%"],
            bottom: 30,
            avoidLabelOverlap: false,
            label: {
              formatter: " {titleBg|{c}}个 \n {txt|{b}}",
              show: true,
              rich: {
                titleBg: {
                  fontSize: 22,
                  fontWeight: "bold",
                  align: "left",
                  // color: "#00E4FF",
                },
                sum: {},
                txt: {
                  fontSize: 14,
                  color: "#ABDAFE",
                  align: "left",
                },
              },
            },
            data: [
              {
                value: 0,
                name: "已完成",
                label: {
                  color: "#00FFE5",
                },
                itemStyle: {
                  normal: {
                    color: {
                      colorStops: [
                        {
                          offset: 0,
                          color: "#00FFE5", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#008073", // 100% 处的颜色
                        },
                      ],
                    },
                  },
                },
              },
              {
                value: 0,
                name: "未完成",
                label: {
                  color: "#00E4FF",
                },
                itemStyle: {
                  normal: {
                    color: {
                      colorStops: [
                        {
                          offset: 0,
                          color: "#00E4FF", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#008097", // 100% 处的颜色
                        },
                      ],
                    },
                  },
                },
              },
            ],
            labelLine: {
              normal: {
                show: true,
              },
            },
          },
        ],
      },
      options: [
        {
          value: "0",
          label: "公共区域",
          children: [
            {
              value: "yizhi",
              label: "人行",
            },
            {
              value: "fankui",
              label: "安防",
            },
            {
              value: "xiaolv",
              label: "消防点",
            },
            {
              value: "kekong",
              label: "消防通道",
            },
            {
              value: "kekong",
              label: "废水",
            },
          ],
        },
        {
          value: "1",
          label: "电梯管理",
          children: [
            {
              value: "yizhi",
              label: "公司1",
            },
            {
              value: "fankui",
              label: "公司2",
            },
            {
              value: "xiaolv",
              label: "公司3",
            },
          ],
        },
        {
          value: "3",
          label: "阳光工厂",
          children: [
            {
              value: "yizhi",
              label: "工厂1",
            },
            {
              value: "fankui",
              label: "工厂2",
            },
            {
              value: "xiaolv",
              label: "工厂3",
            },
          ],
        },
        // {
        //   value: "4",
        //   label: "特种设备",
        // },
      ],
      showInfo: false,
      showInfo2:false,
      showInfo1:false,
      showInfo3:false,
      startVal: 0,
      info: "",
      info2:"",
      info1:'',
      info3:'',
      page:1,
      page_size:5,
      total:0,
      player:null,
      video_loading:false,
      video_success:true,
      videoCode:'',
      event_loading:false,
    };
  },
  computed: {},

  created() {
    // this.getEcharts();
    
  },
  mounted() {
    // this.$api.device.getFaceEvents().then(res =>{
    //   console.log(res)
    // })
    this.getList();
    this.getAllEquipmentDisplay();
    this.getAllEquipmentDisplay1();
    this.getNumberOfEvents();
    this.getCameraWithRegion();
    this.getWarningEvents();
    // this.getPreviewStream();
  },
  methods: {
    handlePagination(page, page_size){
      this.page = page;
      this.page_size = page_size;
      this.getWarningEvents();
    },
    handleVideo(value){
      let obj = this.$refs.cascader.getCheckedNodes();
      console.log(obj[0].data)
      this.camera_list = obj[0].data.cameraList;
      this.camera_list_index = 0;
      this.getPreviewStream(obj[0].data.cameraList[0].cameraIndexCode);
    },
    equipmentShowClick(item, index){
      console.log(item)
      console.log(index)
      this.cpListz[index].type = !this.cpListz[index].type;
      switch (item.textz){
        case '监控':
          this.positionShow1 =this.cpListz[index].type;
          this.cpListz[index].imgz=this.positionShow1?"imgsrc1-st":"imgsrc1";
          break;
        case '门闸':
          this.positionShow2=this.cpListz[index].type;
          this.cpListz[index].imgz=this.positionShow2?"imgsrc2-st":"imgsrc2";
          break;
        case '球机':
          this.positionShow3=this.cpListz[index].type;
          this.cpListz[index].imgz=this.positionShow3?"imgsrc3-st":"imgsrc3";
          break;
        case '特种':
          this.positionShow4 = this.cpListz[index].type;
          this.cpListz[index].imgz=this.positionShow4?"imgsrc4-st":"imgsrc4";
          break;
      }
    },
    alarmEventPopUp(item){
      console.log(item)
      let data = {
        eventId: item.info1.eventId,
        location: item.info1.location||-1,
        happenTime: item.info1.happenTime,
        msg: item.info1.msg,
        license: -1,
        img: []
      }
      if(item.info1.linkageResult){
        data.picUrls = item.info1.linkageResult.picUrls;
        data.svrIndexCode = item.info1.linkageResult.svrIndexCode;
      }
      if(item.info1.data&&item.info1.data.sendTime){
        if(item.info1.data.FireEscapeDetection){
          data.img = [item.info1.data.FireEscapeDetection.BackGroudImage.resourcesContent, item.info1.data.FireEscapeDetection.Target[0].LicensePlateImage.resourcesContent]
          data.license = item.info1.data.FireEscapeDetection.Target[0].licensePlate;
        }else if(item.info1.data.fielddetection){
          data.img = [item.info1.data.fielddetection[0].imageUrl]
        }else{
          data.img = [
            item.info1.data.captureLibResult[0].targetAttrs.bkgUrl,
            item.info1.data.captureLibResult[0].faces[0].URL, 
            item.info1.data.captureLibResult[0].image
          ]
        }
      }
      this.$nextTick(()=>{
        // this.$refs.Dialog.showInfo = false;
        this.$refs.Dialog2.init(data);
      });
    },
    // item3:function(){
    //   this.positionShow=!this.positionShow
    //   if(!this.positionShow){
    //     this.cpButton1d4='nullValue'
    //     this.cpBntextz='nullValue'
    //   }
    //   else if(this.positionShow){
    //     this.cpButton1d4=""
    //     this.cpBntextz="cptextz-st"
    //   }
    // },
    cpbuttonShowClick(){
      this.cpbuttonShow=!this.cpbuttonShow
      if(!this.cpbuttonShow){
        this.imgsrc5="imgsrc5",
        this.cpButton5="cpButton"
      }
      else if(this.cpbuttonShow){
        this.imgsrc5="nullValue",
        this.cpButton5='nullValue'
      }
    },
    backOff(){
      if(this.video_loading){
        return
      }
      this.camera_list_index = this.camera_list_index==0?this.camera_list.length-1:this.camera_list_index-1;
      this.getPreviewStream(this.camera_list[this.camera_list_index].cameraIndexCode);
    },
    forward(){
      if(this.video_loading){
        return
      }
      this.camera_list_index = this.camera_list_index==this.camera_list.length-1?0:this.camera_list_index+1;
      this.getPreviewStream(this.camera_list[this.camera_list_index].cameraIndexCode);
    },
    equipmentAlarmShowClick(){
      this.equipmentAlarmShow=!this.equipmentAlarmShow
    },
    handleSecurity(idx) {
      if (idx == 1) {
        this.$router.push({ path: "/video" });
      } else if (idx == 0) {
        this.$router.push({ path: "/device" });
      } else if (idx == 2) {
        this.$router.push({ path: "/fire" });
      }
    },
    handleShow(item, type) {
      console.log(item)
      // this.showInfo = false;
      this.$nextTick(()=>{
        this.$refs.Dialog.showInfo = false;
        // this.$refs.Dialog1.showInfo2 = false;
        // this.$refs.Dialog2.showInfo1 = false;
        // this.$refs.Dialog3.showInfo3 = false;
      })
      switch (type){
        case 1:
          this.$nextTick(()=>{
            this.$refs.Dialog.init();
            this.info = item.info
          })
          break;
        case 2:
          // this.$nextTick(()=>{
          //   this.$refs.Dialog1.init();
          //   this.info1 = item.info
          // })
          // if(asd==null){
          //   this.showinfo1imgsrc=require('')
          // }
          break;
        case 3:
          let data = {
            eventId: item.event[0].eventId,
            location: item.event[0].regionName||-1,
            happenTime: item.event[0].happenTime,
            msg: item.event[0].srcName,
            license: -1,
            img: []
          }
          // if(item.event[0].linkageResult){
          //   data.picUrls = item.event[0].linkageResult.picUrls;
          //   data.svrIndexCode = item.event[0].linkageResult.svrIndexCode;
          // }
          if(item.event[0].data){
            if(item.event[0].data.FireEscapeDetection){
              data.img = [item.event[0].data.FireEscapeDetection.BackGroudImage.resourcesContent, item.event[0].data.FireEscapeDetection.Target[0].LicensePlateImage.resourcesContent]
              data.license = item.event[0].data.FireEscapeDetection.Target[0].licensePlate;
            }else if(item.event[0].data.fielddetection){
              data.img = [item.event[0].data.fielddetection[0].imageUrl]
            }else{
              data.img = [
                item.event[0].data.captureLibResult[0].targetAttrs.bkgUrl,
                item.event[0].data.captureLibResult[0].faces[0].URL, 
                item.event[0].data.captureLibResult[0].image
              ]
            }
          }
          console.log(data)
          this.$nextTick(()=>{
            this.$refs.Dialog2.init(data);
          })
          break;
        case 4:
          // this.$nextTick(()=>{
          //   this.$refs.Dialog3.init();
          //   this.info3 = item.info
          // })
          break;
      }
    },
    handleNumber() {
      this.numberShow = !this.numberShow;
    },
    handleOperation() {
      this.operationShow = !this.operationShow;
    },
    handleMaintain() {
      this.maintainShow = !this.maintainShow;
    },
    statisticsShowz(){
      this.eventShowz =!this.eventShowz;
    },
    wisdomz(){
      this.wisdomzShow =!this.wisdomzShow;
    },
    videoElarge(){
      console.log(this.videoCode)
      if(this.video_loading){
        return;
      }
      this.$nextTick(()=>{
        this.$refs.videoModal.init(this.videoCode);
      })
    },
    videoListz(){
      this.videoListShow =!this.videoListShow;
    },
    videoListClass(videoListy){
      this.videoActive = videoListy;
    },
    inputValueChange(){
      this.inputValuez= e.target.value
    },
    handleInputs(val) {
      console.log(this.value, 111);
    },
    //设备数量图表
    // async getEcharts() {
    //   let res = await this.$api.device.numberOfDeviceStates();
    //   if (res.code == 200) {

    //     this.optionState.title.text =
    //       parseInt(res.data[0].countStatus) + parseInt(res.data[1].countStatus);
    //   }
    // },
    //设备数量
    async getList() {
      let res = await this.$api.home.getBaseInfo();
      console.log(res)
      if (res.code == 200) {
        this.deviceList[3].sum = res.data.monitoringEquipment;
        this.deviceList[1].sum = res.data.ballMachine;
        this.deviceList[2].sum = res.data.accessControl;
        this.deviceList[0].sum = res.data.specialEquipment;
        this.optionState.series[0].data[1].value =
          res.data.malfunctioningDevice; // 故障
        this.optionState.series[0].data[0].value = res.data.normalEquipment; //正常
        this.optionState.title.text =
          parseInt(res.data.malfunctioningDevice) +
          parseInt(res.data.normalEquipment);
      }
    },
    //事件数量及近5分钟发生的报警
    async getNumberOfEvents() {
      let res = await this.$api.device.getNumberOfEvents();
      console.log(res)
      if(res.code == 200){
        this.list2[0].wt = res.data.carEventNum;
        this.list2[1].wt = res.data.riverEventNum;
        this.list2[2].wt = res.data.faceEventNum;
        if(res.data.riverEvent){
          this.positionImgCameraLocation[2].showinfo1imgsrc =require('@/assets/device/icon-red.png')
          this.positionImgCameraLocation[2].showinfo1imgclass ='iconImgCameraRed'
          this.positionImgCameraLocation[2].flag = 3
          this.positionImgCameraLocation[2].event = res.data.riverEvent
        }
        if(res.data.carEvent){
          this.positionImgCameraLocation[3].showinfo1imgsrc = require('@/assets/device/icon-red.png')
          this.positionImgCameraLocation[3].showinfo1imgclass ='iconImgCameraRed'
          this.positionImgCameraLocation[3].flag = 3
          this.positionImgCameraLocation[3].event = res.data.carEvent
        }
        if(res.data.faceEvent){
          this.positionImgCameraLocation[4].showinfo1imgsrc = require('@/assets/device/icon-red.png')
          this.positionImgCameraLocation[4].showinfo1imgclass ='iconImgCameraRed'
          this.positionImgCameraLocation[4].flag = 3
          this.positionImgCameraLocation[4].event = res.data.faceEvent
        }
      }
    },
    //实时监控的下拉选择
    async getCameraWithRegion() {
      let that = this, arr=[];
      let res = await this.$api.device.getParkCamera();
      console.log(res)
      if(res.code == 200){
        that.video_list = res.data.child
        if(res.data.child[0].child){
          that.camera_list = res.data.child[0].child[0].cameraList;
          arr[0] = res.data.child[0].name;
          arr[1] = res.data.child[0].child[0].name;
        }else{
          arr[0] = res.data.child[0].name;
          that.camera_list = res.data.child[0].cameraList;
        }
        that.video_list_name=arr;
        that.camera_list_index = 0;
        that.getPreviewStream(that.camera_list[0].cameraIndexCode);
      }

    },
    //设备报警的接口
    getWarningEvents(){   
      let params = {
        PageNO: this.page,
        PageSize: this.page_size,
      }
      let that = this;
      this.equipmentAlarmListData = [];
      this.event_loading = true;
      this.$api.device.getWarningEvents(params).then(res =>{
        console.log(res)
        that.event_loading = false;
        if(res.code == 200){
          that.equipmentAlarmListData = res.data.data.map((item)=>{
            return{
              title:"设备报警",
              time:item.happenTime,
              place:item.location,
              message:item.msg,
              info1:item,
            }
          })
          that.total = res.data.total;
        }
      })
      
    },
    //获取预览流
    async getPreviewStream(code){
      let that = this;
      const url = "/api/getCameraPreviewURL?cameraIndexCode="+code;
			const time = + new Date();
			const sign_str = `POST\n${accept}\n${content_type}\nx-ca-key:${app_key}\nx-ca-timestamp:${time}\n${url}`;
			const hash = this.$CryptoJS.HmacSHA256(sign_str, app_secret);
			const base64 = hash.toString(this.$CryptoJS.enc.Base64);
      this.video_loading = true;
      this.video_success = true;
      if(that.player){
        that.player.JS_Stop(that.player.currentWindowIndex).then(
          () => {
          console.info('JS_Stop success');
          // do you want...
          },
          (err) => {
          console.info('JS_Stop failed:', err);
          // do you want...
          }
        );

        that.player = null;
        document.getElementById('player_1').innerHTML = ""
      }
      axios({
        url: process.env.NODE_ENV === 'production' ? `http://127.0.0.1:1000${url}` : `/api${url}`,
        method: "post",
        withCredentials: false,
        headers: {
			    "Accept": accept,
			    "Content-Type": content_type,
          "X-Ca-Key": app_key,
			    "X-Ca-Signature": base64,
			    "X-Ca-Timestamp": time,
          "X-Ca-Signature-Headers": "x-ca-key,x-ca-timestamp",
			    "Access-Control-Allow-Origin": "*",
			    "Access-Control-Allow-Headers": "content-type",
        },
      }).then((res) => {
        console.log(res)
        if(!res.data.data){
          that.video_loading = false;
          that.video_success = false;
          return
        }
        that.videoCode = res.data.data.url;
        that.player = new window.JSPlugin({
          szId: 'player_1',
          szBasePath: "/",
          iMaxSplit: 1,
          iCurrentSplit: 1,
          openDebug: true,
          oStyle: {
            borderSelect: '#000',
          }
        })
        let index = that.player.currentWindowIndex, playURL = res.data.data.url, mode = 1;
        that.video_loading = false;
        that.player.JS_Play(playURL, { playURL, mode }, index).then(
          () => { console.log('realplay success') },
          e => { 
            console.error(e);
            that.player = null;
            document.getElementById('player_1').innerHTML = ""
            that.video_success = false;
           }
        )
			})
    },
    //设备维护列表
    async getAllEquipmentDisplay1() {
      let data = {
        equipmentType: "7",
      };
      let res = await this.$api.device.getEquipmeny(data);
      if (res.code == 200) {
        this.list1 =  res.data.dataList.map((item, idx) => {
          // let pos = this.fixedPosition[item.buildingId + item.buildingArea]
          return {
            name: item.equipmentName,
            info: item,
            left: "55%",
            top: "32%",
            taskList: [],
          }
          // this.getEquipmenyTask(this.list1[idx + 1]);
        });
        console.log(this.list1)
      }
    },
    async getAllEquipmentDisplay() {
      let data = {
        equipmentType: "6",
      };
      let res = await this.$api.device.getEquipmeny(data);
      if (res.code == 200) {
        this.list =  res.data.dataList.map((item, idx) => {
          let pos = this.fixedPosition[item.buildingId + item.buildingArea]
          return {
            name: item.equipmentName,
            left: pos.left,
            top: pos.top,
            info: item,
            taskList: [],
          }
          // this.getEquipmenyTask(this.list1[idx + 1]);
        });
        // res.data.dataList.forEach((item, idx) => {
        //   this.list[idx].info = item;
        //   this.list[idx].name = item.equipmentName;
        //   this.getEquipmenyTask(this.list[idx]);
        // });
      }
    },
    async getEquipmenyTask(item) {
      let data = {
        equipmentId: item.info.equipmentId,
      };
      let res = await this.$api.device.getEquipmenyTask(data);
      if (res.code == 200) {
        item.taskList = res.data.dataList;
      }
    },
    async getEquipmenyTask2(item) {
      let data = {
        equipmentId: item.info.equipmentId,
      };
      let res = await this.$api.device.getEquipmenyTask2(data);
      if (res.code == 200) {
        item.taskList = res.data.dataList;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-image: url("../../assets/home/bg.png");
  background-position: bottom center;
  background-size: 100% 100%;
}
h5 {
  color: #fff;
  font-size: 0.3rem;
  font-family: "YouSheBiaoTiHei";
  padding-left: 0.55rem;
  padding-top: 0.1rem;
}
.imgs {
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
}
.left {
  position: absolute;
  top: 1rem;
  left: 0.22rem;

  .top {
    width: 5.4rem;
    height: 4.35rem;
    background-image: url("../../assets/device/numberBg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-bottom: 0.3275rem;
    .deviceUl {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 0.5rem 0.15rem 0.35rem;
      li {
        position: relative;
        width: calc(50% - 0.2rem);
        height: 1.4rem;
        // background-image: url("../../assets/energy/icon1.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .pBox {
          position: absolute;
          left: 51%;
          top: 22%;
          .p1 {
            font-size: 0.4rem;
            font-weight: 700;
            color: #00e4ff;
            span {
              font-size: 0.12rem;
            }
          }
          .p2 {
            font-size: 0.12;
            color: #abdafe;
          }
        }
      }
    }
  }

  .bottom {
    width: 5.4rem;
    height: 4.4rem;
    background-image: url("../../assets/device/deviceBg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    .devices {
      width: 5.4rem;
      margin-top: 0.1rem;
      display: flex;
      color: #abdafe;
      justify-content: space-around;
      span {
        display: inline-block;
        width: 0.2rem;
        height: 0.2rem;
        margin-right: 0.1rem;
      }
      .noDevice {
        display: flex;
        align-items: center;
        span {
          background: linear-gradient(0deg, #00e4ff, #00e4ff, #008097);
        }
      }
      .yesDevice {
        display: flex;
        align-items: center;
        span {
          background: linear-gradient(0deg, #00ffe5, #00ffe5, #008073);
        }
      }
    }
  }
}
.right {
  position: absolute;
  right: 0.22rem;
  top: 1rem;
  // width: 5.4rem;
  // height: 10.5rem;
  // background-image: url("../../assets/device/rightBg.png");
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
  ul {
    overflow-y: auto;
    width: 5.2rem;
    height: 9.5rem;
    margin-top: 0.2rem;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    li {
      letter-spacing: 0.01rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 0.7rem;
      line-height: 0.7rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding-left: 0.1rem;
      font-size: 0.2rem;
      color: #6793b8;
      position: relative;
      cursor: pointer;
      span {
        font-weight: 700;
        color: #fff;
      }
      i {
        position: absolute;
        right: 0;
        img {
          width: 0.15rem;
        }
      }
      &:hover {
        color: #fff;
      }
    }
    .lis {
      background-image: url("../../assets/fire/li.png");
    }
    .liss {
      background-color: transparent;
    }
  }
  /* 滚动条样式 */
  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  ::-webkit-scrollbar {
    width: 4px;
    height: 10px;
    //   background-color: #00d8ff;
  }
  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px #08101d;
    // border-radius: 10px;
    background-color: #0b1933;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    // -webkit-box-shadow: inset 0 0 6px #152847;
    background-color: #00d8ff;
  }
  &.hidden {
    background-image: url("../../assets/home/titleBg.png");
    height: 0.6125rem;
    .imgs {
      transform: rotate(180deg);
    }
    ul {
      display: none;
    }
  }
}
.center {
  position: absolute;
  right: 6rem;
  top: 0.7rem;
  color: #fff;
  .iconBox {
    display: flex;
    ul {
      display: flex;
      li {
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 0.17rem;
        font-weight: bold;
        text-align: center;
        span {
          position: absolute;
          bottom: 0.1rem;
        }
      }
    }
    .inputBoxs {
      position: relative;
      top: 0.3rem;
      // width: 2.05rem;
      line-height: 0.4rem;
      border-radius: 0.25rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      p {
        padding-left: 0.2rem;
        font-weight: 700;
        span {
          padding-left: 0.4rem;
        }
      }
      .inputs {
        position: absolute;
        left: -0.08rem;
        top: -0.06rem;
      }
    }
  }
}
.position {
  img {
    display: inline-block;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
  }
  .positionImg1 {
    position: absolute;
    left: 17.5%;
    top: 35%;
  }
  .positionImg2 {
    left: 26%;
    top: 39%;
  }
  .positionImg3 {
    left: 32.5%;
    top: 46%;
  }
  .positionImg4 {
    left: 46%;
    top: 53%;
  }
  .positionImg5 {
    left: 54%;
    top: 33.5%;
  }
  .positionImg6 {
    left: 54.5%;
    top: 62%;
  }
  .positionImg7 {
    left: 73%;
    top: 63%;
  }
  .positionImg8 {
    left: 77%;
    top: 55%;
  }
  .positionImg9 {
    left: 81%;
    top: 48%;
  }
  .positionImg10 {
    right: 10%;
    bottom: 0%;
    z-index: 99999;
  }
  .positionImg11 {
    position: absolute;
    cursor: pointer;
    z-index: 1010;

  }
}
.active {
  display: none;
}
.actives {
  display: block;
}
.urlBox {
  position: fixed;
  left: 6rem;
  top: 0.8rem;
  color: #fff;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px RGBA(16, 122, 167, 1);
  div {
    width: 1.2rem;
    height: 0.5rem;
    line-height: 0.5rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    font-size: 0.17rem;
    cursor: pointer;
    user-select: none;
    border-right: solid 1px RGBA(16, 122, 167, 1);
    background-color: RGBA(3, 38, 68, 0.6);
  }
  .li1 {
  }
  .li2 {
    border-right-width: 0px;
  }
  .isactive {
    font-weight: 700;
    color: #00d8ff;
    background-image: url("../../assets/home/f_btn.png");
  }
}
</style>
<style lang="scss">
.inputBoxs {
  .el-input__inner {
    width: 1.4rem;
    border-radius: 0.2rem;
    background-color: transparent;
    border: 0;
    color: #fff;
    font-weight: 700;
  }
}
.el-cascader__dropdown {
  background-color: rgba(0, 51, 74, 0.5);
  border: 1px solid #00d8ff;
  color: #00bee0;
}
.el-cascader-menu {
  color: #00bee0;
  border-right: solid 1px #00d8ff;
}
.el-cascader-node {
  .el-cascader-node__postfix {
    opacity: 0;
  }
  &:hover,
  &:focus {
    background-color: transparent !important;
    background: linear-gradient(
      to right,
      rgba(0, 216, 255, 0.3),
      rgba(0, 216, 255, 0)
    ) !important;
  }
  &.is-active,
  &.in-active-path {
    background-color: transparent !important;
    background: linear-gradient(
      to right,
      rgba(0, 216, 255, 0.3),
      rgba(0, 216, 255, 0)
    ) !important;
  }
  .el-cascader-node__label {
    color: #00bee0;
  }
}
.el-cascader-node.in-active-path {
  color: #00bee0;
  .el-cascader-node__postfix {
    opacity: 1;
  }
}

.partySelects {
  background-image: url("../../assets/partybuilding/select.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  border: 0;
  .el-select-dropdown__item {
    color: #abdafe;
  }
  .el-select-dropdown {
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-image: url("../../assets/partybuilding/selectBg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #00d8ff;
    font-weight: 700;
    background-color: transparent;
  }
}
.el-popper[x-placement^="bottom"] .popper__arrow {
  display: none;
}

.right-topz{
  width:5.4rem;
  // height:1.95rem;
  background-image: url("../../assets/device/eventBg2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom:0.3125rem;
  // padding-bottom: 17px;
  padding: 0 0.2375rem 17px 0.275rem;
}
.right-top-imgz{
  width:0.75rem;
  height:0.75rem;
  margin-right:0.1375rem;
}
.right-top-rightz{
  width: calc(100% - 0.89rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.right-top-liz{
  width:49%;
  height:0.75rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  // margin-top:0.375rem;
  margin-top:0.2125rem;
}
.big-wt{
  color:#ffffff;
  margin-bottom:0.0625rem;
  width: 100%;
  display: flex;
  align-items: flex-end;
  .sm-num {
    max-width: 87%;
    font-size: 0.35rem;
    font-family: 'MicrosoftYaHei-Bold';
    font-weight:700;
    line-height: 27px;
    overflow: hidden;//溢出隐藏
    white-space: nowrap;//禁止换行
    text-overflow: ellipsis;//...
  }
  .sm-wt{
    font-family: 'MicrosoftYaHei';
    font-size:0.1375rem;
    font-weight: normal;
  }
  
}
.sm-blue{
  color:#ABDAFE;
  font-family: 'Adobe Heiti Std R';
  font-size:0.169625rem;
}
.right-top-z{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.right-top-liz-margin{
  margin-bottom:0.1625rem;
}
.right-centerz{
  width:5.4rem;
  height:4.2125rem;
  background-image: url("../../assets/device/wisdomzBg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom:0.3125rem;
  .select-view {
    display: flex;
    justify-content: space-between;
    padding-right: 15px;
  }
}
.right-center-z{
  width:4.8125rem;
  height:2.725rem;
  background-image: url("../../assets/device/videoBg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-left:0.29375rem;
  margin-right:0.27375rem;
  margin-top:0.25rem;
  padding: 3px 3px;
  position: relative;
}
.videoText{
  font-family:' MicrosoftYaHei-Bold';
  font-size:0.234375rem;
  display: flex;
  justify-content: center;
  color:#FFFFFF;
  margin-top:0.1375rem;
}
.right-center-video-z{
  width:100%;
  height:100%;
  object-fit: fill
}
.big_right-center-z{
  width:12.03125rem;
  height:5.45rem;
  background-image: url("../../assets/device/videoBg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 7.5px 7.5px;
  z-index: 1020;
  position: absolute;
  left:25%;
  top:25%;
}
.controls{
  // width:4.8125rem;
  // height:2.725rem;
  position: absolute;
  top:0;
  // z-index: 1100;
}
.relative{
  position:relative
}
.controls1{
  width:12.03125rem;
  height:5.45rem;
  top:0;
  z-index: 1100;
  position: absolute;
}
.video-height{
  height:5.2625rem;
  width:11.84375rem;
}
.video-width-fill{
  width:11.84375rem;
  object-fit: fill
}
.video-list{
  background-image: url(../../assets/device/video-list.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width:1.575rem;
  height:0.425rem;
  position:absolute;
  top:0.05rem;
  // left:3.6125rem;
  left: 3.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left:0.1125rem;
  padding-right:0.1125rem;
}
.imgz{
  width:0.175rem;
  height:0.1rem;
}
.video-list-text{
  font-family: 'MicrosoftYaHei-Bold';
  font-size:0.18225rem;
  color:#F9FDFF;
  white-space:nowrap;/*强制在同一行内显示所有文本，直到文本结束或者遭遇br标签对象才换行。*/
  overflow: hidden;/*超出部分隐藏*/
  text-overflow: ellipsis;/*隐藏部分以省略号代替*/
  background-color:transparent;
  border:none;
}
.video-list-bottom{
  background-image: url(../../assets/device/video-list-bottom.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width:1.575rem;
  height:1.325rem;
  position:absolute;
  left: 3.3rem;
  top:0.525rem;
  z-index: 1200;
  padding-left:2px;
  padding-right: 2px;
  padding-top:0.125rem;
  padding-top:0.125rem;
  overflow: hidden;
  overflow-y: auto;
}
.video-list-li{
  height:0.3625rem;
  width:1.525rem;
  display: flex;
  align-items: center;
  padding-left:0.0875rem;
  padding-right:0.0875rem;
  font-size: 0.18225rem;
  color:#F9FDFF;
  font-family: 'MicrosoftYaHei-Bold';
}
.video-list-li-st{
  background-color:#10A0CF
}
.right-bottomZ{
  background-image: url(../../assets/device/equipmentAlarm.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width:5.4rem;
  height:4.3rem;
}
.equipmentAlarmList-fd{
  width:4.875rem;
  height: calc(100% - 95px);
  display:flex;
  flex-direction: column;
  padding-left:0.3125rem;
  padding-right:0.2125rem;
  // padding-bottom:0.3375rem;
  padding-top:0.3375rem;
}
.overflow-width{
  overflow-y: auto;
  overflow-x:hidden;
  width:4.875rem;
  // width:4.9875rem;
}
.equipmentAlarmList{
  height:1.275rem;
  width:4.6625rem;
  margin-bottom:0.25rem;
  cursor: pointer;
}
.equipmentAlarmList-top{
  width:100%;
  height:0.475rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left:0.15rem;
  padding-right:0.15rem;
  background-image: url(../../assets/device/list-top.png);
  background-size:100% 100%;
}
.equipmentAlarmList-bottom{
  background-image: url(../../assets/device/list-bottom.png);
  background-size:100% 100%;
  width:100%;
  height:0.8rem;
  padding:0.15rem 0.15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.eAtitle{
  color:#FFF17A;
  font-family: 'MicrosoftYaHei-Bold';
  font-size:0.260375rem;
  font-weight: Bold;
}
.eAtime{
  color:#FFFFFF;
  font-family: 'MicrosoftYaHei-Bold';
  font-size:0.15625rem;
}
.cptextz-st{
  color:#FCEF79;
  font-family: 'MicrosoftYaHei-Bold';
  font-size:0.15625rem;
  // top:0.4rem;
  top:0.39rem;
  left:0.1625rem;
  position: absolute;
}
.spanText{
  color:#F3FCFF;
  font-size:0.18225rem;
  font-family: 'MicrosoftYaHei-Bold';
}
.cpButton-st{
  background-size:100% 100%;
  background-image: url(../../assets/device/cpButton-st.png);
  width:0.625rem;
  height: 0.6125rem;
  position: relative;
  margin-left:0.25rem;
}
.cpButton{
  background-size:100% 100%;
  background-image: url(../../assets/device/cpButton.png);
  width:0.625rem;
  height: 0.6125rem;
  position: relative;
}
.controlPanel{
  position:absolute;
  top:1.225rem;
  // left:13.9rem;
  left:17.4rem;
  display:flex;
}
.controlPanel-z{
  position:absolute;
  top:1.225rem;
  left:13.9rem;
  // left:17.4rem
  display:flex;
}
.cpButton{
  margin-left:0.25rem;
}
.cptextz{
  color:#8FEFFF;
  font-family: 'MicrosoftYaHei-Bold';
  font-size:0.15625rem;
  top:0.4rem;
  left:0.1625rem;
  position: absolute;
}
.imgsrc1-st{
  background-image: url(../../assets/device/imgsrc1-st.png);
  background-size:100% 100%;
  width:0.275rem;
  height:0.275rem;
  position: absolute;
  top:0.05rem;
  left:0.175rem;
}
.imgsrc1{
  background-image: url(../../assets/device/imgsrc1.png);
  background-size:100% 100%;
  width:0.275rem;
  height:0.275rem;
  position: absolute;
  top:0.05rem;
  left:0.175rem;
}
.imgsrc2-st{
  background-image: url(../../assets/device/imgsrc2-st.png);
  background-size:100% 100%;
  width:0.25rem;
  height:0.3125rem;
  position: absolute;
  top:0.05rem;
  left:0.175rem;
}
.imgsrc2{
  background-image: url(../../assets/device/imgsrc2.png);
  background-size:100% 100%;
  width:0.25rem;
  height:0.3125rem;
  position: absolute;
  top:0.05rem;
  left:0.175rem;
}
.imgsrc3-st{
  background-image: url(../../assets/device/imgsrc3-st.png);
  background-size:100% 100%;
  width:0.3375rem;
  height:0.3125rem;
  top:0.0625rem;
  left:0.14375rem;
  position: absolute;
}
.imgsrc3{
  background-image: url(../../assets/device/imgsrc3.png);
  background-size:100% 100%;
  width:0.3375rem;
  height:0.3125rem;
  position: absolute;
  top:0.0625rem;
  left:0.14375rem;
}
.imgsrc4-st{
  background-image: url(../../assets/device/imgsrc4-st.png);
  background-size:100% 100%;
  width:0.2875rem;
  height:0.3rem;
  top:0.0625rem;
  left:0.1625rem;
  position: absolute;
}
.imgsrc4{
  background-image: url(../../assets/device/imgsrc4.png);
  background-size:100% 100%;
  width:0.2875rem;
  height:0.3rem;
  top:0.0625rem;
  left:0.1625rem;
  position: absolute;
}
.imgsrc5-st{
  background-image: url(../../assets/device/imgsrc5-st.png);
  background-size:100% 100%;
  width:0.45rem;
  height:0.4125rem;
  top:0.0875rem;
  left:0.075rem;
  position: absolute;
}
.imgsrc5{
  background-image: url(../../assets/device/imgsrc5.png);
  background-size:100% 100%;
  width:0.45rem;
  height:0.4125rem;
  top:0.0875rem;
  left:0.075rem;
  position: absolute;
}
.positionImgCamera{
    position: absolute;
    cursor: pointer;
    z-index: 1011;
}
.iconImgCamera{
    display: inline-block;
    position: absolute;
    width: 0.375rem;
    height: 0.375rem;
}
.iconImgCameraRed{
  display: inline-block;
  position: absolute;
    width: 1.275rem;
    height: 1.275rem;
    left:-0.3rem;
    top:-0.25rem;
}
.iconImgGate{
  display: inline-block;
  position: absolute;
  width: 0.375rem;
  height: 0.4625rem;
}
.positionImgGate{
  position: absolute;
  cursor: pointer;
  z-index: 1010;
}
.positionImgSpeedDomeCameras{
  position: absolute;
  cursor: pointer;
  z-index: 1010;
}
.iconImgSpeedDomeCameras{
  width:0.4125rem;
  height:0.375rem;
  display: inline-block;
  position: absolute;
}
.iconIMG5{
  width:1.175rem !important;
  height: 1.1875rem !important;;
}
.navigation-left{
  position:absolute;
  top:1.1625rem;
  left:0.1875rem;
  height:0.4rem;
  width:0.4rem;
  cursor: pointer;
  display: none;
}
.navigation-right{
  position:absolute;
  top:1.1625rem;
  right:-4.5875rem;
  height:0.4rem;
  width:0.4rem;
  cursor: pointer;
  display: none;
}
.right-center-z:hover .navigation-left{
  display: block;
}
.right-center-z:hover .navigation-right{
  display: block;
}
.weather{
  position:absolute;
  top:0.1rem;
  right:0.3rem;
  width:1.4rem;
  height:0.275rem;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-between;
  color:#39D6FE;
  font-size:0.25rem;
  font-family: 'MicrosoftYaHei';
}
.weather-img{
  width:0.3rem;
  height:0.25rem;
}
.DeviceEquipmentAlarmPage{
  width: 4.875rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}



</style>
<style scoped>
.equipmentAlarmList-fd >>>.el-loading-mask,
.right-center-z >>>.el-loading-mask {
  background-color: #00000000;
}
.select-box >>>.el-input__inner {
  border: 1px solid #00d8ff;
  background-color: rgba(0, 51, 74, 0.5);
  color: #fff;
}
.error-tip {
    height: 50px;
    color: #fff;
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}
.error-tip p {
    /* float: left; */
    font-size: 20px;
    padding-right: 16px;
    animation-name: myani;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    animation-direction: alternate;
    animation-fill-mode: both;
}
.s1 {
    animation-delay: 0.2s;
}
.s2 {
    animation-delay: 0.4s;
}
.s3 {
    animation-delay: 0.6s;
}
.s4 {
    animation-delay: 0.8s;
}
.s5 {
    animation-delay: 1s;
}
.s6 {
    animation-delay: 1.2s;
}
.s7 {
    animation-delay: 1.4s;
}
.s8 {
    animation-delay: 1.6s;
}
@keyframes myani {
    0%, 100% {
        padding-bottom: 0;
    }
    50% {
        transform: translateY(-20px);
    }
}
</style>
