<template>
  <div class="select-box-delect">
    <div v-show="videoListShow" @click="videoListz">
      <div class="video-list">
        <div class="video-list-text" @click="handleShow" @blur="handleClose">
          {{ obj.label }}
        </div>
        <img 
          src="@/assets/device/imgz.png" 
          alt="" 
          class="imgz"
         
        />
      </div>
      <!-- <transition name="el-zoom-in-top">
        <div class="select-options" v-if="show">
          <ul>
            <li
              v-for="(item, idx) in options"
              :key="idx"
              :class="value === item.value ? 'active' : ''"
              @click="handleSelect(item.value)"
            >
              <span>{{ item.label }}</span>
            </li>
          </ul>
        </div>
      </transition> -->
    </div>
    <div v-show="!videoListShow">
      <div class="video-list" @click="videoListz">
        <div class="video-list-text" @click="handleShow" @blur="handleClose">
          {{ obj.label }}
        </div>
        <img
        src="@/assets/device/imgz.png"
        class="imgz"
        alt="" 
        style="transform:rotate(180deg)"
        />
      </div>
      <div class="video-list-bottom">
        <div
          class="video-list-li"
          v-for="(item, idx) in options"
          :key="idx"
          :class="value === item.value ? 'video-list-li-st' : ''"
          @click="handleSelect(item.value, idx)"
          >
          {{item.label}}
        </div>
      </div>
    </div>
    <!-- <span class="videoText videoText1">{{ obj.label }}</span> -->
  </div>
</template>

<script>
export default {
  props: ["value", "list", 'placeholder'],
  data() {
    return {
      // videoSrcZ:'http://zj.yiyuchu.com/lzpmng/dataManagementFile/2021/11/26/20211126104310786222.mp4',
      options: [],
      obj: {
        label: "",
        value: "",
      },
      show: false,
      videoListShow:true,
    };
  },
  created() {
    this.options = this.list;
    if (this.value) {
      let obj = this.list.find((it) => it.value === this.value);
      this.obj = obj;
    } else {
      this.obj = {
        label: this.placeholder ? this.placeholder:'请选择',
        value: "",
      };
    }
  },
  watch: {
    value(val) {
      if (val) {
        let obj = this.list.find((it) => it.value === val);
        this.obj = obj;
      } else {
        this.obj = {
          label: this.placeholder ? this.placeholder:'请选择',
          value: "",
        };
      }
    },
    list(val) {
      this.options = val
    }
  },
  mounted() {
    //点击其他区域关闭下拉菜单
    document.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) this.show = false;
    });
    // this.getMonitoringLocation();
  },
  methods: {
    handleShow() {
      this.show = true;
    },
    handleClose() {
      this.show = false;
    },
    handleSelect(value, index) {
      this.show = false;
      this.$emit("input", value, index);
      this.videoListShow=!this.videoListShow
      // this.$emit('getName',this.videoSrcZ)
      // this.$emit("getData", this.videoSrcZ);
      // console.log(this,videoSrcZ)
      // this.$emit("change", value);
      // this.$emit("getData",value)
    },
    videoListz(){
      this.videoListShow =!this.videoListShow;
    },
    // videoListClass(videoListy){
    //   this.videoActive = videoListy;
    // },
    //实时监控的下拉选择
    // async getMonitoringLocation() {
    //   let res = await this.$api.device.getMonitoringLocation();
    //   console.log(res)
    //   if(res.code == 200){
    //     this.video_list[0].label = res[0].cameraName;
    //     this.video_list[1].label = res[1].cameraName;
    //     this.video_list[2].label = res[2].cameraName;
    //     this.video_list[3].label = res[3].cameraName;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
// .select-box {
//   position: absolute;
//   display: inline-block;
// }
// .select {
//   display: inline-block;
//   position: relative;
//   font-size: 0.2rem;
//   color:#FFF;
//   // background-image: url("../../assets/home/kuang2.png");
//   height: 0.40rem;
//   // background-size: 100% 100%;
//   top: 0;
//   line-height: 0.40rem;
//   text-align: center;
//   padding-right: 0.4rem;
//   cursor: pointer;
//   padding-left: 0.1rem;
//   &::after {
//     content: "";
//     width: 0;
//     height: 0;
//     position: absolute;
//     right: 0.1rem;
//     top: calc(50% - 0.045rem);
//     border-top: solid 0.09rem #fff;
//     border-left: solid 0.09rem transparent;
//     border-right: solid 0.09rem transparent;
//   }
// }
// .select-options {
//   position: absolute;
//   z-index: 999;
//   left: 0;
//   top: 100%;
//   min-width: 100%;
//   ul {
//     background-color: rgba(0, 0, 0, 0.8);
//     margin-top: 0.1rem;
//     border: solid 1px rgba(174, 246, 94, 0.7);
//     padding: 0 4px;
//     padding-bottom: 4px;
//     li {
//       padding: 0 0.1rem;
//       line-height: 2;
//       cursor: pointer;
//       margin-top: 4px;
//       font-size: .2rem;
//       white-space: nowrap;
//       text-align: left;
//       color:#FFF;
//       &:hover,
//       &.active {
//         background-image: linear-gradient(
//           to right,
//           rgba(174, 246, 94, 1),
//           rgba(0, 0, 0, 0.3)
//         );
//       }
//     }
//   }
// }


.video-list{
  background-image: url(../../assets/device/video-list.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width:1.575rem;
  height:0.425rem;
  position:absolute;
  top:0.05rem;
  left:3.6125rem;
  // left: 3.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left:0.1125rem;
  padding-right:0.1125rem;
  cursor: pointer;
}
.imgz{
  width:0.175rem;
  height:0.1rem;
}
.video-list-bottom{
  background-image: url(../../assets/device/video-list-bottom.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width:1.575rem;
  height:1.325rem;
  position:absolute;
  left: 3.6125rem;
  top:0.525rem;
  z-index: 1200;
  padding-left:2px;
  padding-right: 2px;
  padding-top:0.125rem;
  padding-bottom:0.125rem;
  overflow: hidden;
  overflow-y: auto;
}
.video-list-li{
  height:0.3625rem;
  width:1.525rem;
  display: flex;
  align-items: center;
  padding-left:0.0875rem;
  padding-right:0.0875rem;
  font-size: 0.18225rem;
  color:#F9FDFF;
  font-family: 'MicrosoftYaHei-Bold';
}
.video-list-li-st{
  background-color:#10A0CF
}
.select-box-delect{
  position: static !important;
  display: block !important;
}
.videoText{
  font-family:' MicrosoftYaHei-Bold';
  font-size:0.234375rem;
  display: flex;
  justify-content: center;
  color:#FFFFFF;
  margin-top:0.1375rem;
}
.videoText1{
  position: absolute;
  top:3.45rem;
  width:5.4rem;
}
</style>
